.App {
   padding-top: 20vh;
   text-align: center;
   height: 100vh;
   background: linear-gradient(0deg, #282828 0%, #000000 100%);
}

.App p {
   color: white;
   font-size: xx-large;
}